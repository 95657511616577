<template>
  <div>
    <h1>फोटो गैलरी</h1>

    <GalleryList />
  </div>
</template>

<script>
import GalleryList from "../components/GalleryList";
export default {
  name: "PhotoGallery",
  components: {
    GalleryList,
  },
};
</script>
